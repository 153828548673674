<template>
  <div>
    <MainBanner
      Title="Workplace Form"
      Image="/img/main-banner.webp"
      TextPosition="bottom"
      Height="middle"
    />
    <div class="main-continer mt-5">
      <div class="container row mx-auto mb-5">
        <div class="col-12 col-lg-6 offset-lg-3 mb-5 mt-5 mt-lg-0">
          <form
            @submit.prevent="submitForm"
            autocomplete="off"
            class="w-100 contact-form"
          >
            <div class="form-group">
              <label for="userName">Adınız Soyadınız</label>
              <input
                v-model="form.userName"
                v-on:input="removeSpace"
                v-bind:class="{
                  error: $v.form.userName.$error,
                  valid: $v.form.userName.$dirty && !$v.form.userName.$invalid,
                }"
                type="text"
                class="form-control"
              />
              <p class="form-warning" v-if="!$v.form.userName.alpha">
                <span class="svg-icon icons-alert"></span> Ad Soyad şeklinde
                giriniz.
              </p>
              <p
                class="form-warning"
                v-if="!$v.form.userName.nameSurnameLength"
              >
                <span class="svg-icon icons-alert"></span> En az 2 karakter
                girmelisiniz
              </p>
              <p class="form-warning" v-if="!$v.form.userName.required">
                <span class="svg-icon icons-alert"></span> Bu alan zorunludur.
              </p>
            </div>
            
            <div class="form-group">
              <label for="userName">Okul Adı</label>
              <input
                v-model="form.schoolName"
                v-bind:class="{
                  error: $v.form.schoolName.$error,
                  valid: $v.form.schoolName.$dirty && !$v.form.schoolName.$invalid,
                }"
                type="text"
                class="form-control"
              />
              <p class="form-warning" v-if="!$v.form.schoolName.minLength">
                <span class="svg-icon icons-alert"></span> En az 2 karakter
                girmelisiniz
              </p>
              <p class="form-warning" v-if="!$v.form.schoolName.required">
                <span class="svg-icon icons-alert"></span> Bu alan zorunludur.
              </p>
            </div>

            <div class="row">
              <div class="form-group col-12 col-md-6">
                <label for="userName">Şehir</label>
                <input
                  v-model="form.city"
                  v-on:input="removeSpace"
                  v-bind:class="{
                    error: $v.form.city.$error,
                    valid: $v.form.city.$dirty && !$v.form.city.$invalid,
                  }"
                  type="text"
                  class="form-control"
                />
                <p class="form-warning" v-if="!$v.form.city.minLength">
                  <span class="svg-icon icons-alert"></span> En az 2 karakter
                  girmelisiniz
                </p>
                <p class="form-warning" v-if="!$v.form.city.required">
                  <span class="svg-icon icons-alert"></span> Bu alan zorunludur.
                </p>
              </div>
              
              <div class="form-group col-12 col-md-6">
                <label for="userName">İlçe</label>
                <input
                  v-model="form.district"
                  v-on:input="removeSpace"
                  v-bind:class="{
                    error: $v.form.district.$error,
                    valid: $v.form.district.$dirty && !$v.form.district.$invalid,
                  }"
                  type="text"
                  class="form-control"
                />
                <p class="form-warning" v-if="!$v.form.district.minLength">
                  <span class="svg-icon icons-alert"></span> En az 2 karakter
                  girmelisiniz
                </p>
                <p class="form-warning" v-if="!$v.form.district.required">
                  <span class="svg-icon icons-alert"></span> Bu alan zorunludur.
                </p>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-12 col-md-6">
                <label for="userMail">E-Posta</label>
                <input
                  v-model="form.userMail"
                  v-on:keydown="key"
                  v-bind:class="{
                    error: $v.form.userMail.$error,
                    valid:
                      $v.form.userMail.$dirty && !$v.form.userMail.$invalid,
                  }"
                  inputmode="email"
                  type="email"
                  class="form-control"
                />
                <p class="form-warning" v-if="!$v.form.userMail.email">
                  <span class="svg-icon icons-alert"></span>Yanlış mail formatı
                </p>
                <p class="form-warning" v-if="!$v.form.userMail.required">
                  <span class="svg-icon icons-alert"></span>Bu alan zorunludur.
                </p>
              </div>

              <div class="form-group col-12 col-md-6">
                <label for="userPhone">Telefon Numarası</label>
                <the-mask
                  name="phone"
                  id="frmPhoneNumA"
                  autocomplete="tel"
                  mask="0(###) ### ## ##"
                  placeholder="0(---) --- -- --"
                  v-model="form.userPhone"
                  v-bind:class="{
                    error: $v.form.userPhone.$error,
                    valid:
                      $v.form.userPhone.$dirty && !$v.form.userPhone.$invalid,
                  }"
                  type="tel"
                  class="form-control"
                />
                <p class="form-warning" v-if="!$v.form.userPhone.required">
                  <span class="svg-icon icons-alert"></span>Bu alan zorunludur.
                </p>
                <p class="form-warning" v-if="!$v.form.userPhone.minLength">
                  <span class="svg-icon icons-alert"></span>Yanlış telefon
                  formatı
                </p>
              </div>
            </div>

            <label class="custom-checkbox">
              <a
                href="https://www.bilimseferberligi.org/kvkk"
                target="_blank"
                >Kişisel verilerin korunması ve işlenmesine ilişkin politikayı
              </a>
              okudum, onaylıyorum.
              <input
                type="checkbox"
                v-model="form.terms_accepted"
                v-bind:class="{
                  error: $v.form.terms_accepted.$error,
                  valid:
                    $v.form.terms_accepted.$dirty &&
                    !$v.form.terms_accepted.$invalid,
                }"
              />
              <span class="checkmark"></span>
            </label>

            <div class="captchaContainer">
              <vue-recaptcha 
                sitekey="6LdqRYkdAAAAADMTRrN8tCiANfX80guwDWlQ7B7A"
                @verify="markRecaptchaAsVerified"
                ref="recaptcha"
                
              ></vue-recaptcha>
            </div>

            <div class="mt-4">
              <ButtonRounded
                Text="Gönder"
                :isRouting="false"
                color="blue"
                :disabled="disabled"
              />
            </div>
          </form>
          <p
            v-if="returnMsg.length > 0"
            v-html="returnMsg"
            class="mb-5 mt-3"
          ></p>
        </div>
      </div>
    </div>
    <FooterLanding />
  </div>
</template>
<script>
import ButtonRounded from "@/components/ButtonRounded";
import MainBanner from "@/components/MainBanner";
import FooterLanding from "@/components/FooterLanding";

import { VueRecaptcha } from 'vue-recaptcha';

import {
  required,
  email,
  minLength,
  helpers,
  requiredIf,
} from "vuelidate/lib/validators";
const nameSurnameLength = (value) => {
  let isValid = true;
  value = value.trim()
  value.split(" ").forEach((e) => {
    if (e.length < 2) {
      isValid = false;
    }
  });
  return isValid;
};
export default {
  name: "Contact",
  components: { MainBanner, ButtonRounded, FooterLanding, VueRecaptcha },
  data() {
    return {
      recaptchaVerified: false,
      disabled: false,
      isLoading: false,
      fullPage: false,
      pageContent: '',
      cities: '',
      form: {
        userName: "",
        userMail: "",
        userPhone: "",
        terms_accepted: false,
        schoolName: "",
        city:"",
        district:"",
      },
      returnMsg: "",
    };
  },
  validations: {
    form: {
      userName: {
        required,
        nameSurnameLength,
        alpha: helpers.regex(
          "alpha",
          /^[a-z\u00C0-\u02AB'´`]+\.?\s([a-z\u00C0-\u02AB'´`]+\.?\s?)+$/i
        ),
      },
      userMail: {
        required,
        email: email,
      },
      userPhone: {
        required,
        minLength: minLength(10),
      },
      schoolName: {
        required,
        minLength: minLength(2),
      },
      city: {
        required,
        minLength: minLength(2),
      },
      district: {
        required,
        minLength: minLength(2),
      },
      terms_accepted: {
        checked: (value) => value === true,
      },
    },
  },
    metaInfo() {
    return {
      title: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.',
      meta: [
        {
          vmid: "description",
          name: "description",
          //content: this.metaData.description,
        },
        // {name: 'keywords', content: this.meta_data.meta_keywords},
        // {property: 'og:image', content: this.meta_data.meta_image},
        { property: "og:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
        { property: "og:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
        { property: "og:url", content: window.location.href },
        { name: "twitter:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
        { name: "twitter:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
        { name: "twitter:url", content: window.location.href},
        // {name: 'twitter:image', content: this.meta_data.meta_image},
        // {property: 'og:image:width', content: '540'},
        // {property: 'og:image:height', content: '282'},
      ],
    };
  },
  mounted(){
    this.getPageContent("contact");
  },
  methods: {
    markRecaptchaAsVerified(response){
      this.recaptchaVerified = true;
    },
    getPageContent(page) {
      this.$api.getPageContent(page).then((response) => {
        this.pageContent = response;
      });
    },
    removeSpace: function (event) {
      this.form.userName = this.form.userName.replace( /\s\s+/g, ' ' ).trimStart();
      this.form.userName = this.form.userName.replace(/[0-9`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
      
      this.form.city = this.form.city.replace(/[0-9`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
      this.form.district = this.form.district.replace(/[0-9`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
    },
    key: function (event) {
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    },
    submitForm() {
      let captchaContainer = document.querySelector(".captchaContainer");
      
      this.$v.$touch();
      
      if (!this.$v.form.$invalid) {
        if(this.recaptchaVerified){
          captchaContainer.classList.remove("error");
          
          let data = new FormData();
          data.append("name", this.form.userName.trim());
          data.append("school_name", this.form.schoolName.trim());
          data.append("city", this.form.city);
          data.append("district", this.form.district);
          data.append("email", this.form.userMail);
          data.append("phone", this.form.userPhone);
          this.disabled = true;

          
          this.$api.sendWorkplaceForm(data).then((response) => {
            this.returnMsg = response.message;
            setTimeout(() => {
              this.disabled = false;
            }, 1500);
          });

        }else{
          captchaContainer.classList.add("error");
        }

      } else {}

    },
  },
};
</script>
<style lang="scss">
.contact-map {
  h2 {
    font-size: 1.75rem;
    font-weight: 500;
  }
  h3 {
    font-size: 1.125rem;
    font-weight: 600;
  }
  p {
    font-size: 1rem;
    font-weight: 400;
    b {
      font-weight: 600;
    }
  }
  
}
.contact-form {
  textarea {
    height: 180px;
  }
}
.contact-form .custom-selectbox,
.contact-form input.form-control,
.contact-form textarea.form-control {
  background: var(--blueLight);
  border: 2px solid var(--blueLight);
  color: #fff;
}
</style>